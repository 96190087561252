<template>
    <figure class="text-center">
        <blockquote class="blockquote h1">
            <p>东望山有兽，名曰白泽，能言语。王者有德，明照幽远则至。</p>
        </blockquote>
        <figcaption class="blockquote-footer">
            <cite title="Source Title">《山海经》</cite>
        </figcaption>
        <p>白泽，中国古代神话中的瑞兽。能言语，通万物之情，知鬼神之事，“王者有德”才出现，能辟除人间一切邪气。</p>
        <p>我希望能够清晰的获取当前golang项目的包依赖关系，开发了这个工具，名为白泽。</p>
        <p>作为一名后端研发工程师，我的前端功力显然是不够强大。在使用过程中如果给您带来不便，请见谅。</p>
    </figure>
    <h5>如果你：</h5>
    <ul class="list-group">
        <li class="list-group-item">
            对这个工具有改进意见，欢迎点击
            <a href="https://github.com/pjimming/baize/issues/new" target="_blank" rel="noopener noreferrer">
                issue
            </a>
            ，提出您的宝贵意见。
        </li>
        <li class="list-group-item">
            有更好的解决方案，请点击
            <a href="https://github.com/pjimming/baize/compare" target="_blank">
                pull request
            </a>
            ，提交您的实现！
        </li>
    </ul>
    <figure class="text-center">
        <img class="img-fluid rounded" src="../assets/logo.png">
    </figure>
</template>

<script>
export default {
    name: "AboutBaize",
}
</script>

<style scoped></style>
