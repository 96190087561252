<template>
    <el-backtop :right="100" :bottom="100" />
</template>

<script>
export default {
    name: "BackToTop",
}
</script>

<style scoped></style>