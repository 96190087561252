<template>
<ContentBase>
    <AboutBaize />
</ContentBase>
</template>

<script>
import ContentBase from '@/components/ContentBase.vue';
import AboutBaize from '@/components/AboutBaize.vue';

export default {
    name: "AboutView",
    components: {
        ContentBase,
        AboutBaize,
    },
}
</script>

<style>

</style>
